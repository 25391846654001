import { useExperiment } from "../../../../contexts/ExperimentContext";
import styles from "./Button.module.scss";

export default function Button(props) {
  const { className, outline, text, children, ...childrenProps } = props;

  const { experiment } = useExperiment();

  const classNames = `${styles["Button"]} ${
    outline ? styles["--outline"] : ""
  } ${text ? styles["--text"] : ""} ${styles["--new"]} ${className}`.trim();

  return (
    <button className={classNames} {...childrenProps}>
      {children}
    </button>
  );
}
