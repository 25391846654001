import { useContext, createContext, useEffect, useState } from "react";
import { setCookie, getCookie } from "cookies-next";
import pick from "pick-random-weighted";
import { useRouter } from "next/router";
import { getValue } from "../config/Lead";
import { createFunnelEvent } from "../config/Analytics";

const ExperimentContext = createContext();

export const useExperiment = () => useContext(ExperimentContext);
const isProd = process.env.NODE_ENV === "production";

const allExp = {
  default: 0.0,
  price_slider_fetch_9_12_24: 1.0,
};

const experimentConfig = Object.keys(allExp).map(function (key) {
  return [key, allExp[key]];
});

export const EXPERIMENT_ENUM = "expid";

export const ExperimentContextProvider = ({ children }) => {
  const router = useRouter();
  const chosenExperiment = router.query.force_experiment_group
    ? router.query.force_experiment_group
    : process.browser
    ? window.localStorage.getItem(EXPERIMENT_ENUM)
    : getCookie(EXPERIMENT_ENUM);

  const defaultExperiment =
    allExp[chosenExperiment] !== undefined && allExp[chosenExperiment] !== null
      ? chosenExperiment
      : "default";

  const [experiment, setExperiment] = useState(defaultExperiment);
  const [loaded, setLoaded] = useState(false);
  const [rocketMortgage, setRocketMortgage] = useState(false);

  useEffect(() => {
    setRocketMortgage(
      router.query.step === "rocket" && getValue("country") === "CA"
    );
  }, [router.query.step, experiment]);

  useEffect(() => {
    let pickedExp = chosenExperiment;

    const cookieExp = getCookie(EXPERIMENT_ENUM);
    const sessionUUID =
      window.localStorage.getItem("sessionUUID") || getCookie("sessionUUID");

    if (router.query.utm_sitelink === "t") {
      createFunnelEvent({
        eventType: `experiment_default_sitelink`,
        step: router.query.step,
        leadType: router.query.leadType,
        details: {
          exp: defaultExperiment,
          session_uuid: sessionUUID,
        },
      });
      // 14 day max age, 60 seconds in a minute, 60 minutes in an hour, 24 hours in a day, 14 days in 2 weeks
      setCookie(EXPERIMENT_ENUM, defaultExperiment, {
        maxAge: 60 * 60 * 24 * 14,
      });

      window.localStorage.setItem("expid", defaultExperiment);
      setLoaded(true);
      return;
    }

    if (!chosenExperiment) {
      const exp = pick(experimentConfig);
      pickedExp = exp;
      setExperiment(exp);
      createFunnelEvent({
        eventType: "experiment_chosen",
        step: router.query.step || "landing",
        leadType: router.query.leadType,
        details: {
          exp,
          session_uuid: sessionUUID,
        },
      });

      // 14 day max age, 60 seconds in a minute, 60 minutes in an hour, 24 hours in a day, 14 days in 2 weeks
      setCookie(EXPERIMENT_ENUM, exp, {
        maxAge: 60 * 60 * 24 * 14,
      });

      window.localStorage.setItem("expid", exp);
    } else if (router.query.force_experiment_group) {
      createFunnelEvent({
        eventType: `experiment_group_forced`,
        step: router.query.step,
        leadType: router.query.leadType,
        details: {
          exp: router.query.force_experiment_group,
          session_uuid: sessionUUID,
        },
      });
      // 14 day max age, 60 seconds in a minute, 60 minutes in an hour, 24 hours in a day, 14 days in 2 weeks
      setCookie(EXPERIMENT_ENUM, chosenExperiment, {
        maxAge: 60 * 60 * 24 * 14,
      });
      window.localStorage.setItem("expid", chosenExperiment);
    } else if (chosenExperiment !== defaultExperiment) {
      createFunnelEvent({
        eventType: `experiment_default`,
        step: router.query.step,
        leadType: router.query.leadType,
        details: {
          exp: defaultExperiment,
          chosenExperiment,
          session_uuid: sessionUUID,
        },
      });
      // 14 day max age, 60 seconds in a minute, 60 minutes in an hour, 24 hours in a day, 14 days in 2 weeks
      setCookie(EXPERIMENT_ENUM, defaultExperiment, {
        maxAge: 60 * 60 * 24 * 14,
      });

      window.localStorage.setItem("expid", defaultExperiment);
    }

    if (!cookieExp && chosenExperiment) {
      createFunnelEvent({
        eventType: `reset_experiment_cookie_to_chosen`,
        step: router.query.step,
        leadType: router.query.leadType,
        details: {
          session_uuid: sessionUUID,
        },
      });
      setCookie(EXPERIMENT_ENUM, chosenExperiment, {
        maxAge: 60 * 60 * 24 * 14,
      });
    }

    setLoaded(true);
  }, [
    chosenExperiment,
    router.query.force_experiment_group,
    router,
    defaultExperiment,
  ]);

  return (
    <ExperimentContext.Provider
      value={{
        experiment,
        rocketMortgage,
        setExperiment,
      }}
    >
      {loaded ? children : null}
    </ExperimentContext.Provider>
  );
};

export default ExperimentContext;
